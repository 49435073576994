import { useCallback, useEffect, useState } from "react"

const Contact = () => {
    const email =  'bWFpbHRvOmljaXNzaWJhZG9yQGdtYWlsLmNvbQ==';
    const pm = 'bW9jLmxpYW1nQHJvZGFiaXNzaWNp';
    

    const encodeEmail= (text:string, special?: boolean):string => {
        let otext = text;
        if(special) {
        var textArea = document.createElement('textarea');
        textArea.innerText = text;
         otext =  textArea.innerHTML;
        } 
        return btoa(otext);
    }

    const getPlainEmail = ():string => {
        return encodeEmail('');

    }

    const decodeEmail = (text: string, special?:boolean):string => {
        let dtext = text;
        if(special) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
           dtext =  textArea.value;
        }
         return atob(dtext);

    }
    return <div className="contact">
     <div style={{backgroundColor: '#fefefe', padding: 16, marginTop: 64}}>
        <h2>Contact Us</h2>
        <div>
            <p>Feel free to get in touch for any inquiries, feedback or support</p>
        </div>
        <div>
            <p>EMAIL: <a href="javascript:window.location.href=atob('bWFpbHRvOmljaXNzaWJhZG9yQGdtYWlsLmNvbQ==')" style={{unicodeBidi: 'bidi-override', direction: 'rtl'}}>{decodeEmail(pm, true)}</a></p>
        </div>
      </div>
    </div>
}

export default Contact;