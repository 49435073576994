const HomeHeader = () => {
    return (
        <div className="home-header">
            <h1 style={{textTransform: 'uppercase', margin: 0, marginBottom: 16}}>Authorverse</h1>    
            <div style={{textTransform: 'uppercase', lineHeight: 2}}>
                Home of fun and exciting books. Exploring worlds through words.
            </div>            
        </div>
    )
}
export default HomeHeader;