import HomeContent from "../components/home-content";
import HomeHeader from "../components/home-header";

const Home = () => {
    return (
        <div className="home-page">
            <HomeHeader />
            <HomeContent />
        </div>
    )
}

export default Home;