import { Link } from "react-router-dom";

const HomeContent = () => {
    return <div className="home-content">
        <div className="inner-content">
            <img className="card" style={{borderRadius: 8}} src="images/bkg.jpg" alt="bright knight" />
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h3 style={{margin: '8px 0', fontSize: '1.45rem', textTransform: 'uppercase'}}>Bright Knight</h3>
                <h3 style={{margin: '0', fontSize: '1.45rem'}}>Goddess</h3>
                <p>Available on Kobo Reader</p>
                <div>
                    <Link to="https://www.kobo.com/ww/en/ebook/bright-knight"><button className="action-button">Buy Now!</button></Link>
                </div>
            </div>
        </div>
    </div>
}

export default HomeContent;