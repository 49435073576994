 import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './components/navbar';
import { Outlet } from 'react-router';

import SocialLinks from './components/social-links';

function App() {
  return (
    <div className="App">
      <header className="App-header card">
        <div className='header-content'>
          <NavBar />
          <div className="social" style={{padding: '0 16px'}}>
            <SocialLinks />
          </div>
        </div>
      </header>
      <div>
      <Outlet />
      </div>
      <div className='social-mobile'>
        <SocialLinks />
      </div>
    </div>
  );
}

export default App;
