import { Link } from "react-router-dom";
import { FacebookIcon, InstagramIcon, TikTokIcon, YoutubeIcon } from "./icons"

const SocialLinks = () => {
    return (<div  style={{display: 'flex', alignItems: 'center', gap: 16}}>
        <Link to="https://www.tiktok.com/@icissibador"><TikTokIcon size={12} style={{background: '#fff', borderRadius: '50%', padding:4}} fill="#430505" /></Link>
        <Link to="https://www.facebook.com/profile.php?id=61552598955681"><FacebookIcon size={12} style={{background: '#fff', borderRadius: '50%', padding:4}} fill="#430505"  /></Link>
        <Link to="https://www.youtube.com/channel/UCrxBmL_Hgari1zPGnwAbWpA"><YoutubeIcon  size={12} style={{background: '#fff', borderRadius: '50%', padding:4}} fill="#430505" /></Link>
        <Link to ="https://www.instagram.com/icissibador/"><InstagramIcon size={12} style={{background: '#fff', borderRadius: '50%', padding:4}} fill="#430505"  /></Link>
    </div>)
}

export default SocialLinks;