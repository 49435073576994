import { Link } from "react-router-dom";

const NavBar = () => {
    return (
    <div className="nav-bar">
        <ul>
        <Link to="/"><li>Home</li></Link>
            <li>About</li>
            <li>Books</li>
            <Link to="/contact"><li>Contact</li></Link>
        </ul>
    </div>);

}

export default NavBar;